@use '@angular/material' as mat;
@use './theme-colors' as theme-colors;

@use './partials/navigation.component-theme' as navigation;
@use './partials/sidenav.component-theme' as sidenav;
@use './partials/admin-page.component-theme' as admin-page;
@use './partials/user-permission.component-theme' as user-permission;
@use './partials/table.component-theme' as table;

html {
  font-family: var(--mat-sys-body-large-font), serif;
  font-size: 14px;
  @include mat.theme((
    color: (
      theme-type: light,
      primary: theme-colors.$primary-palette,
      tertiary: theme-colors.$tertiary-palette,
    ),
    typography: 'Montserrat',
    density: -1,
  ), $overrides: (
    on-background: black,
    on-surface: black,
  ));

  h1 {
    font-weight: var(--mat-sys-headline-large-weight);
  }

  h2 {
    font-weight: var(--mat-sys-headline-large-weight);
  }
}

// set density
@include mat.form-field-density(-2);
@include mat.icon-button-density(-4);

// custom component theme
@include navigation.theme();
@include sidenav.theme();
@include admin-page.theme();
@include user-permission.theme();
@include table.theme();
