@mixin theme() {
  $primary: var(--mat-sys-primary);

  admin-navigation {
    mat-nav-list {
      .mat-mdc-subheader {
        color: $primary;
      }
    }

    a.mat-mdc-list-item {
      border-radius: 0 !important;

      &.active {
        &::after {
          background-color: $primary !important;
        }
      }
    }

  }
}
