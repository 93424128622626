@use'./theme/theme';

@use'./partials/layout';
@use'./partials/admin-page';
@use'./partials/table-avatar';

html, body {
  height: 100%;
}

body {
  margin: 0;
}

.autosize .mdc-tooltip__surface {
  max-width: unset !important;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, .12);
  border-radius: 12px;
}

.tag[data-value="example"] {
  background-color: #FFB700 !important;
}

.mat-tooltip {
  white-space: pre;
  max-width: initial !important;
}

.mat-mdc-dialog-panel.dialog-pre-wrap {
  .mat-mdc-dialog-content {
    white-space: pre-wrap;
  }
}

.read {
  background: linear-gradient(100deg, #C0C0C0 30%, #D3D3D3 50%, #C0C0C0 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;

  > * {
    opacity: 0.25;
  }
}

// opex-report
.opex-report {
  mat-row {
    min-height: 36px;
  }
  .subtotal {
    font-weight: 500;
    background-color: aliceblue;
  }
  .total {
    display: none;
  }
  mat-footer-row {
    font-weight: bold;
    background-color: cornflowerblue;
    color: white;
  }

  .subtotal {
    pointer-events: none;

    //& > mat-cell span {
    //  pointer-events: auto;
    //}
  }
}
