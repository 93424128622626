@mixin theme() {
  $on-primary: var(--mat-sys-on-primary);
  $primary: var(--mat-sys-primary);
  $surface: var(--mat-sys-surface);

  .admin-page {
    .page-header {
      .mdc-fab {
        border-radius: 50%;
      }
    }

    .carded {
      background-color: $surface;
    }

    .page-content {
      mat-nav-list {
        a.mat-mdc-list-item {
          &.active {
            color: $on-primary;
            background-color: $primary;

            .mdc-list-item__primary-text {
              color: inherit;
            }

            &:hover, &:focus {
              background-color: $primary;
            }
          }
        }
      }
    }
  }
}
