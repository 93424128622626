@mixin theme() {
  $tertiary: var(--mat-sys-tertiary);

  app-user-permission {
    sup {
      line-height: 0;
      font-size: 18px;
      color: $tertiary;
    }
  }
}
