@use '../../scss/partials/layout-bp' as mq;

.admin-page {

  .page-header {
    box-sizing: border-box;
    min-height: 160px;
    flex-shrink: 0;
    padding: 25px 30px 56px 30px;

    @include mq.layout-bp(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .title {
      margin: 0;
    }

    .subtitle {
      opacity: 0.5;
    }
  }

  .page-content {
    margin: -56px 30px 0 30px;

    @include mq.layout-bp(xs) {
      margin-left: 15px;
      margin-right: 15px;
    }

    .page-header {
      box-sizing: border-box;
      padding-left: 0;
      padding-right: 0;
    }

    .page-content {
      margin-left: 0;
      margin-right: 0;
    }

    mat-nav-list {
      position: sticky;
      top: 16px;
      padding-top: 0;

      a.mat-mdc-list-item {
        border-radius: 5px;
        overflow: hidden;
        height: 40px;
        font-size: 14px !important;

        mat-icon {
          height: 18px;
          width: 18px;
          line-height: 1;
          font-size: 18px;
        }
      }
    }

  }

  .carded {
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 2px 18px 1px rgba(49, 53, 72, .1);

    &.overflow {
      overflow: auto;
    }
  }

  .padded {
    padding-left: 25px;
    padding-right: 25px;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }
  }
}
